import * as React from 'react';
import { useMediaQuery } from 'react-responsive'

import { Rank, RankPlace } from '../models/Rank';
import School from '../models/School';
import { useSettings } from '../models/Settings';
import { Colors, Styles } from '../Resources';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SolutionToolbar, { SolutionFilter } from '../components/SolutionToolbar';
import { Box, Typography, Divider, Card, Chip } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faChair, faChild } from '@fortawesome/free-solid-svg-icons';


const eligibleCitizenLabel = [
	{ value: -1, label: '' },
	{ value: 2, label: 'SC' },
	{ value: 1, label: 'PR' },
];

const eligibleDistanceLabel = [
	{ value: -1, label: '' },
	{ value: 3, label: '< 1km' },
	{ value: 2, label: '1-2km' },
	{ value: 1, label: '> 2km' },
];

const SolutionPage: React.FC = () => {
	const settings = useSettings();
	const [filter, setFilter] = React.useState<SolutionFilter | undefined>();
	const [rankPlaces2B, setRankPlaces2B] = React.useState<Array<RankPlace> | undefined>();
	const [rankPlaces2C, setRankPlaces2C] = React.useState<Array<RankPlace> | undefined>();
	const [rankPlaces2CS, setRankPlaces2CS] = React.useState<Array<RankPlace> | undefined>();
	const [solution2B, setSolution2B] = React.useState<Array<RankPlace> | undefined>();
	const [solution2C, setSolution2C] = React.useState<Array<RankPlace> | undefined>();
	const [solution2CS, setSolution2CS] = React.useState<Array<RankPlace> | undefined>();

	React.useEffect(()=>{
		if (!filter) return;
		
		let rank2B = new Rank({
			fromYear: 2022,
			toYear: 2024,
			phaseCode: '2B',
			citizen: filter.citizen,
			distance: filter.distance,
		});
		rank2B.calc();

		let rank2C = new Rank({
			fromYear: 2022,
			toYear: 2024,
			phaseCode: '2C',
			citizen: filter.citizen,
			distance: filter.distance,
		});
		rank2C.calc();

		let rank2CS = new Rank({
			fromYear: 2022,
			toYear: 2024,
			phaseCode: '2CS',
			citizen: filter.citizen,
			distance: filter.distance,
		});
		rank2CS.calc();

		let schools = School.allOnPostal(123456);
		let d1s = schools.distance1;
		let d2s = schools.distance2;

		setRankPlaces2B(rank2B.places.filter((rp: RankPlace)=>{
			if (filter.gender !== 'all' && filter.gender !== rp.school.gender.toLowerCase()) return false;
			return d1s.concat(d2s).find((sch: School)=>rp.school.code === sch.code);
		}));
		setRankPlaces2C(rank2C.places.filter((rp: RankPlace)=>{
			if (filter.gender !== 'all' && filter.gender !== rp.school.gender.toLowerCase()) return false;
			return d1s.concat(d2s).find((sch: School)=>rp.school.code === sch.code);
		}));
		setRankPlaces2CS(rank2CS.places.filter((rp: RankPlace)=>{
			if (filter.gender !== 'all' && filter.gender !== rp.school.gender.toLowerCase()) return false;
			return d1s.concat(d2s).find((sch: School)=>rp.school.code === sch.code);
		}));
		
	}, [filter, setRankPlaces2B, setRankPlaces2C]);

	React.useEffect(()=>{
		if (!rankPlaces2B) return;
		if (!settings.hideBadSolution) {
			setSolution2B(rankPlaces2B);
			return;
		}
		let solution = rankPlaces2B.filter((rp: RankPlace)=>{
			let hasVacancy = rp.sum.vacancy && rp.sum.vacancy > 0;
			let hasBetter = rp.sum.rate === rankPlaces2C?.find((better: RankPlace)=>rp.school.code === better.school.code)?.sum.rate;
			return hasVacancy && !hasBetter;
		});
		setSolution2B(solution);
	}, [rankPlaces2B, rankPlaces2C, setSolution2B, settings.hideBadSolution]);

	React.useEffect(()=>{
		if (!rankPlaces2C) return;
		if (!settings.hideBadSolution) {
			setSolution2C(rankPlaces2C);
			return;
		}
		let solution1 = rankPlaces2C.filter((rp: RankPlace)=>{
			let hasVacancy = rp.sum.vacancy && rp.sum.vacancy > 0;
			return hasVacancy;
		});
		let solution2 = rankPlaces2C.filter((rp: RankPlace)=>{
			let hasVacancy = rp.sum.vacancy && rp.sum.vacancy > 0;
			let hasBetter = rp.sum.rate === rankPlaces2CS?.find((better: RankPlace)=>rp.school.code === better.school.code)?.sum.rate;
			return hasVacancy && !hasBetter;
		});
		setSolution2C(solution1.length === solution2.length ? solution1 : solution2);
	}, [rankPlaces2C, rankPlaces2CS, setSolution2C, settings.hideBadSolution]);

	React.useEffect(()=>{
		if (!rankPlaces2CS || !solution2C) return;
		if (!settings.hideBadSolution) {
			setSolution2CS(rankPlaces2CS);
			return;
		}
		let solution = rankPlaces2CS.filter((rp: RankPlace)=>{
			let hasVacancy = rp.sum.vacancy && rp.sum.vacancy > 0;
			let hasBetter = solution2C!.find((better: RankPlace)=>rp.school.code === better.school.code);
			return hasVacancy && !hasBetter;
		});
		setSolution2CS(solution);
	}, [rankPlaces2CS, solution2C, setSolution2CS, settings.hideBadSolution]);

	return <Box display='flex' flexDirection='column' height='100%'>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} maxWidth={980} sx={{ alignSelf: 'center' }}>
			<SolutionToolbar sx={{ my: 4 }} onFiltered={(filter: SolutionFilter)=>setFilter(filter)} />
			{filter && solution2B && <SolutionGroup phaseCode='2B'>
				{solution2B.map((rp: RankPlace)=><SolutionItem key={rp.no} rankPlace={rp} citizen={filter.citizen} distance={filter.distance} />)}
			</SolutionGroup>}
			<Divider sx={{ my: 4, bgcolor: Styles.divider }} />
			{filter && solution2C && <SolutionGroup phaseCode='2C'>
				{solution2C.map((rp: RankPlace)=><SolutionItem key={rp.no} rankPlace={rp} citizen={filter.citizen} distance={filter.distance} />)}
			</SolutionGroup>}
			<Divider sx={{ my: 4, bgcolor: Styles.divider }} />
			{filter && solution2CS && <SolutionGroup phaseCode='2CS'>
				{solution2CS.map((rp: RankPlace)=><SolutionItem key={rp.no} rankPlace={rp} citizen={filter.citizen} distance={filter.distance} />)}
			</SolutionGroup>}
		</Box>
		<Footer />
	</Box>;
}

interface SolutionGroupProps {
	phaseCode: string;
	children: React.ReactNode;
}

const SolutionGroup: React.FC<SolutionGroupProps> = ({ phaseCode, children }) => {
	return <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap={{ xs: 2, sm: 4 }}>
		<Box mt={{ xs: 0, sm: 2 }} ml={{ xs: 2, sm: 0 }} width={64}><img alt='' src={`images/${phaseCode}.svg`} height={32} /></Box>
		<Box display='flex' flexDirection='column' gap={2} flex={1} maxWidth={520}>{children}</Box>
	</Box>
}

interface SolutionItemProps {
	rankPlace: RankPlace;
	citizen: number;
	distance: number;
}

const SolutionItem: React.FC<SolutionItemProps> = ({ rankPlace, citizen, distance }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	let eligible = rankPlace.sum.vacancy! > 0;
	let noVac;
	if (!eligible) {
		let citiLabel = eligibleCitizenLabel.find(e => e.value === citizen)?.label;
		let distLabel = eligibleDistanceLabel.find(e => e.value === distance)?.label;
		noVac = citiLabel || distLabel ? <>No Vac. for<br />{`${citiLabel} ${distLabel}`}</> : <>No Vac.</>;
	}
	let rate = rankPlace.sum.rate;
	let percent = rate === 1 ? '100' : Number(rate * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
	return <Card key={rankPlace.school.code}>
		<Box display='flex' flexDirection='row'>
			<Box display='flex' flexDirection='column' flex={1} gap={0.5}>
				<Box display='flex' flexDirection='row'>
					<Typography fontSize='lg' fontWeight='bold' width={40} textColor={ eligible ? 'black' : Colors.muiGray }>{rankPlace.no}</Typography>
					<Typography fontSize='lg' fontWeight='bold' textColor={ eligible ? 'black' : Colors.muiGray }>{isTabletOrMobile ? rankPlace.school.shortName : rankPlace.school.name}</Typography>
				</Box>
				<Box display='flex' flexDirection='row' gap={1} ml={5}>
					{rankPlace.school.gender !== 'Mixed' && <Chip variant='soft' size='sm' sx={{ color: '#999', opacity: eligible ? 1 : 0.2 }}>{rankPlace.school.gender}</Chip>}
					{rankPlace.school.gep && <Chip variant='soft' size='sm' sx={{ color: '#999', opacity: eligible ? 1 : 0.2 }}>GEP</Chip>}
					{rankPlace.school.sap && <Chip variant='soft' size='sm' sx={{ color: '#999', opacity: eligible ? 1 : 0.2 }}>SAP</Chip>}
				</Box>
			</Box>
			<Box display='flex' flexDirection='column' textAlign='right' ml={4} gap={0.5} alignSelf='center'>
				{eligible
					? <Typography fontSize='lg' fontWeight='bold'>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
					: <Typography fontSize='xs' textColor='#999'>{noVac}</Typography>}
				{eligible && <Typography fontSize='xs' textColor='#999'><FA icon={faChair} color='#bbb' /> {rankPlace.sum.vacancy} <FA icon={faChild} color='#bbb' /> {rankPlace.sum.applied}</Typography>}
			</Box>
		</Box>
	</Card>;
}

export default SolutionPage;

