import School from './School';
import Registration, { Phase, Enroll } from './Registration';


export class RankPlace {

	no: number | undefined;
	school: School;
	elements: Array<Enroll>;
	sum: Enroll;
	score: Enroll;

	constructor(school: School, phases: Array<Phase>, citizen: number, distance: number) {
		this.school = school;
		this.elements = phases.map(e => e.eligible(citizen, distance));
		this.sum = new Enroll({
			vacancy: this.elements.reduce((sum, e) => sum + (e.vacancy ?? 0), 0),
			applied: this.elements.reduce((sum, e) => sum + (e.applied ?? 0), 0),
			citizen: citizen,
			distance: distance,
		});
		this.score = new Enroll({
			vacancy: phases.reduce((sum, e) => sum + (e.total.vacancy ?? 0), 0),
			applied: phases.reduce((sum, e) => sum + (e.total.applied ?? 0), 0),
			citizen: citizen,
			distance: distance,
		});
	}

}

export class Rank {

	fromYear: number;
	toYear: number;
	phaseCode: string;
	citizen: number;
	distance: number;
	places: Array<RankPlace> = [];
	
	constructor(obj: any) {
		this.fromYear = obj.fromYear;
		this.toYear = obj.toYear;
		this.phaseCode = obj.phaseCode;
		this.citizen = obj.citizen;
		this.distance = obj.distance;
	}

	calc() {
		const years = new Array<Array<Registration>>();
		for(let i = Math.max(this.fromYear, this.toYear); i >= Math.min(this.fromYear, this.toYear); i--) {
			years.push(Registration.P1(i));
		}
		// years.push(Registration.test());
		this.places = years[0].map((reg: Registration) => {
			let school = School.all().find((e: School) => e.code === reg.schoolCode)!;
			let phases = [reg.phase(this.phaseCode)!];
			for (let i = 1; i < years.length; i++) {
				let regs = years[i];
				let reg = regs.find((reg: Registration) => school.code === reg.schoolCode);
				if (!reg) continue;
				phases.push(reg.phase(this.phaseCode)!);
			}
			phases.reverse();
			return new RankPlace(school, phases, this.citizen, this.distance);
		});
		this.places.sort((a: RankPlace, b: RankPlace)=>{
			const aVacancy = a.score.vacancy ?? 0;
			const aApplied = a.score.applied ?? 0;
			const bVacancy = b.score.vacancy ?? 0;
			const bApplied = b.score.applied ?? 0;
			const aRate = aApplied === 0 ? 10000 : (aVacancy / aApplied);
			const bRate = bApplied === 0 ? 10000 : (bVacancy / bApplied);
			if (aRate < bRate) return -1;
			if (aRate > bRate) return 1;
			if (aApplied > bApplied) return -1;
			if (aApplied < bApplied) return 1;
			if (a.school.name < b.school.name) return -1;
			if (a.school.name > b.school.name) return 1;
			return 0;
		});
		for (let i = 0; i < this.places.length; i++) {
			this.places[i].no = i + 1;
		}
	}


}