import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSettings } from '../models/Settings';

import { Card, CardProps, Box, Divider, Typography, Button, Input, Switch } from '@mui/joy';
import { Select, Option } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleRight, faGear } from '@fortawesome/free-solid-svg-icons';


const supportGender = [
	{ value: 'all', label: 'All' },
	{ value: 'mixed', label: 'Mixed' },
	{ value: 'girl', label: 'Girl' },
	{ value: 'boy', label: 'Boy' },
];

const supportCitizen = [
	{ value: -1, label: 'All' },
	{ value: 2, label: 'Singapore Citizen' },
	{ value: 1, label: 'Permanent Resident' },
];

const supportDistance = [
	{ value: -1, label: 'All' },
	{ value: 3, label: 'within 1km' },
	{ value: 2, label: 'between 1-2km' },
	{ value: 1, label: 'outside 2km' },
];

export type SolutionFilter = {
	postal: string,
	gender: string,
	citizen: number,
	distance: number,
}

const solutionFilterToSearchParams = (filter: SolutionFilter) => {
	const sp: URLSearchParams = new URLSearchParams();
	sp.set('post', filter.postal);
	sp.set('gend', filter.gender);
	sp.set('citi', filter.citizen.toString());
	return sp;
}

const searchParamsToSolutionFilter = (sp: URLSearchParams) => {
	let postal = sp.get('post') ?? '';
	let gender = sp.get('gend')?.toLowerCase() ?? '';
	gender = supportGender.map(e => e.value).includes(gender) ? gender : supportGender[0].value;
	let citizen = parseInt(sp.get('citi') ?? '');
	citizen = supportCitizen.map(e => e.value).includes(citizen) ? citizen : supportCitizen[0].value;
	return {
		postal: postal,
		gender: gender,
		citizen: citizen,
	} as SolutionFilter;
}

interface SolutionToolbarProps extends CardProps {
	loading?: boolean;
	onFiltered?: (filter: SolutionFilter) => void;
}

const SolutionToolbar: React.FC<SolutionToolbarProps> = ({ loading = false, onFiltered, ...props }) => {
	const settings = useSettings();
	const [searchParams, setSearchParams] = useSearchParams();
	const filter = React.useMemo(()=>searchParamsToSolutionFilter(searchParams), [searchParams]);
	React.useEffect(() => onFiltered && onFiltered(filter), [filter, onFiltered]);

	return <Card component='form' sx={props.sx} onSubmit={(event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const fi = searchParamsToSolutionFilter(data as URLSearchParams);
		const sp = solutionFilterToSearchParams(fi);
		setSearchParams(sp);
		onFiltered && onFiltered(fi);
	}}>
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Typography fontSize='sm'>Postal Code</Typography>
			<Input defaultValue={filter.postal} placeholder='e.g. 238629' disabled={loading} name='post' size='sm' type='search' />
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Gender</Typography>
			<Select defaultValue={filter.gender} disabled={loading} name='gend' size='sm' sx={{ width: 88 }}>
				{supportGender.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Children of</Typography>
			<Select defaultValue={filter.citizen} disabled={loading} name='citi' size='sm' sx={{ width: 180 }}>
				{supportCitizen.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Button size='sm' loading={loading} endDecorator={<FA icon={faCircleRight} />} type='submit'>Solution</Button>
		</Box>
		<Divider inset='none' sx={{ my: 1 }} />
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Typography fontSize='xs' textColor='#999' endDecorator={<FA icon={faGear} />}>Solution is based on data-sum from 2022 to 2024</Typography>
			<Divider orientation='vertical' inset='none' />
			<Switch checked={settings.hideBadSolution} disabled={loading} onChange={(event)=>settings.hideBadSolution = event.target.checked}
				startDecorator={<Typography fontSize='xs'>Hide Inapplicable</Typography>} />
		</Box>
	</Card>
}

export default SolutionToolbar;


