import Cookie from 'js-cookie';
import { useState, useEffect } from 'react';


class Settings {

	static default = () => new Settings({
		_hideBadSolution: false,
	});

	private _hideBadSolution: boolean;
	private _updateListeners: Array<()=>void>;

	get hideBadSolution() {
		return this._hideBadSolution;
	}

	set hideBadSolution(value: boolean) {
		this._hideBadSolution = value;
		this.save();
	}

	addUpdateListener(listener: ()=>void) {
		this._updateListeners.push(listener);
	}

	removeUpdateListener(listener: ()=>void) {
		const index = this._updateListeners.indexOf(listener);
		if (index === -1) return;
		this._updateListeners.splice(index, 1);
	}

	constructor(obj: any) {
		this._hideBadSolution = obj._hideBadSolution;
		this._updateListeners = [];
	}

	static load = () => {
		const data = Cookie.get('settings');
		let settings;
		if (data) {
			const obj = JSON.parse(data);
			settings = new Settings(obj);
		} else {
			settings = Settings.default();
		}
		return settings;
	}

	private isDiffTo(s: Settings) {
		return this._hideBadSolution !== s._hideBadSolution;
	}

	private save() {
		const data = Cookie.get('settings');
		Cookie.set('settings', JSON.stringify(this));
		if (!data || this.isDiffTo(new Settings(JSON.parse(data)))) {
			this._updateListeners.forEach(e => e());
		}
	}

}

const instance = Settings.load();

export function useSettings() {
	const [update, setUpdate] = useState(0);
	useEffect(()=>{
		const listener = ()=>setUpdate(Date.now());
		instance.addUpdateListener(listener);
		return ()=>{
			instance.removeUpdateListener(listener);
		};
	}, []);
	return instance;
}

