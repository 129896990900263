import * as React from 'react';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Box, Typography } from '@mui/joy';


const HomePage: React.FC = () => {
	return <Box display='flex' flexDirection='column' height='100%'>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} alignItems='center' justifyContent='center'>
			<Typography fontSize='lg' fontWeight='bold' mt={4}>🚧 Coming soon...</Typography>
		</Box>
		<Footer />
	</Box>;
}

export default HomePage;

