import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { P2b2cLogo } from '../Resources';
import { Box, Link, Typography, Divider } from '@mui/joy';


const NavBar: React.FC = () => {
	const nav = useNavigate();
	return <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' mt={4} gap={4}>
		<Link underline='none' onClick={()=>nav('/')}><P2b2cLogo /></Link>
		{/*<Link underline='none' onClick={()=>nav('/')}><Typography textColor='black'>Properties</Typography></Link>*/}
		<Link underline='none' onClick={()=>nav('/p1-school-ranking')}>
			<Typography fontSize='sm' textColor='black'>P1 School Ranking</Typography>
		</Link>
		<Divider orientation='vertical' sx={{ bgcolor: '#ccc', height: '1rem', alignSelf: 'center' }} />
		<Link underline='none' onClick={()=>nav('/p1-optimal')}>
			<Typography fontSize='sm' textColor='black'>P1 Optimal</Typography>
		</Link>
	</Box>;
}

export default NavBar;


