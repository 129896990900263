import * as React from 'react';
import { useMediaQuery } from 'react-responsive'

import { Rank, RankPlace } from '../models/Rank';
import { Styles } from '../Resources';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import RankToolbar, { RankFilter } from '../components/RankToolbar';
import { Box, Typography, Grid, Chip } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faChair, faChild } from '@fortawesome/free-solid-svg-icons';

// import { getBallotFromSgschooling, getBallotDetailsFromMoe } from './Utils';
// getBallotFromSgschooling();
// getBallotDetailsFromMoe();

const eligibleCitizenLabel = [
	{ value: -1, label: '' },
	{ value: 2, label: 'SC' },
	{ value: 1, label: 'PR' },
];

const eligibleDistanceLabel = [
	{ value: -1, label: '' },
	{ value: 3, label: '< 1km' },
	{ value: 2, label: '1-2km' },
	{ value: 1, label: '> 2km' },
];

const RankPage: React.FC = () => {
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	const [filter, setFilter] = React.useState<RankFilter | undefined>();
	const [rankPlaces, setRankPlaces] = React.useState<Array<RankPlace> | undefined>();
	const canvasRefs = React.useRef([]);

	React.useEffect(()=>{
		if (!filter) return;
		let rank = new Rank({
			fromYear: filter.fromYear,
			toYear: filter.toYear,
			phaseCode: filter.phase,
			citizen: filter.citizen,
			distance: filter.distance,
		});
		rank.calc();
		let places = rank.places.filter((rp: RankPlace)=>{
			return (filter.gender === 'all' ? true : filter.gender === rp.school.gender.toLowerCase())
				&& (filter.area === 'all' ? true : filter.area === rp.school.area.toLowerCase());
		});
		canvasRefs.current = new Array(places.length).fill(0).map((_, i) => canvasRefs.current[i] || React.createRef());
		setRankPlaces(places);
	}, [filter, setRankPlaces]);

	React.useEffect(()=>{
		if (!rankPlaces) return;
		rankPlaces.forEach((rp: RankPlace, i: number)=>{
			let ref = canvasRefs.current[i];
			if (!ref) return;
			let canvas = (ref as any).current;
			if (!canvas) return;
			let ctx = canvas.getContext('2d')!;
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			if (rp.elements.length === 1) return;
			if (rp.sum.applied === 0) return;
			let padding = 5;
			let width = canvas.width - padding * 2;
			let height = canvas.height - padding * 2;
			let points = new Array<any>();
			let minY = 10000;
			let maxY = 0;
			for (let i = 0; i < rp.elements.length; i++) {
				let x = width / (rp.elements.length - 1) * i + padding;
				let y = height - rp.elements[i].rate * height + padding;
				minY = Math.min(minY, y);
				maxY = Math.max(maxY, y);
				points.push({ x: x, y: y });
			}
			let offset = canvas.height * 0.5 - ((maxY - minY) * 0.5 + minY);
			ctx.beginPath();
			points.forEach((p: any)=>{
				ctx.moveTo(p.x, p.y + offset);
				ctx.arc(p.x, p.y + offset, 3, 0, 2 * Math.PI);
			});
			ctx.fill();
			ctx.beginPath();
			let origin = points.shift();
			ctx.moveTo(origin.x, origin.y + offset);
			points.forEach((p: any)=>ctx.lineTo(p.x, p.y + offset));
			ctx.stroke();
		});
	}, [rankPlaces]);

	return <>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} maxWidth={980} sx={{ alignSelf: 'center' }}>
			<RankToolbar onFiltered={(filter: RankFilter)=>setFilter(filter)} sx={{ mt: 4 }} />
			{filter && rankPlaces && <Box mt={2}>
				{!isTabletOrMobile && <Grid container spacing={2} flexGrow={1} alignItems='center' p={0} m={0} sx={{ whiteSpace: 'nowrap', borderBottom: Styles.divider }}>
					<Grid xs={0.7}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>No.</Typography></Grid>
					<Grid flex={1}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>School</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>GEP</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>SAP</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>Gender</Typography></Grid>
					<Grid xs={1.35} ml={1}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>Area</Typography></Grid>
					<Grid xs='auto' mx={1}><Typography width={160} fontSize='xs' textColor='#999' fontWeight='normal'>Vacancies / Applied - Trend</Typography></Grid>
					<Grid xs={0.9}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>V/A Rate</Typography></Grid>
				</Grid>}
				{rankPlaces && rankPlaces.map((rp: RankPlace, i: number)=>
					<RankItem key={rp.no} rankPlace={rp} citizen={filter.citizen} distance={filter.distance} canvasRef={canvasRefs.current[i]} isMobile={isTabletOrMobile} />)}
			</Box>}
		</Box>
		<Footer />
	</>;
}

interface RankItemProps {
	rankPlace: RankPlace;
	citizen: number;
	distance: number;
	canvasRef: any;
	isMobile: boolean;
}

const RankItem: React.FC<RankItemProps> = ({ rankPlace, citizen, distance, canvasRef, isMobile }) => {
	let available = rankPlace.sum.applied! > 0;
	let eligible = rankPlace.sum.vacancy! > 0;
	let noVac;
	if (!eligible) {
		let citiLabel = eligibleCitizenLabel.find(e => e.value === citizen)?.label;
		let distLabel = eligibleDistanceLabel.find(e => e.value === distance)?.label;
		noVac = citiLabel || distLabel ? <>No Vac. for<br />{`${citiLabel} ${distLabel}`}</> : <>No Vac.</>;
	}
	let rate = rankPlace.sum.rate;
	let percent = rate === 1 ? '100' : Number(rate * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
	return isMobile ?
	<Box display='flex' flexDirection='column' p={1} sx={{ borderBottom: Styles.divider }}>
		<Box display='flex' flexDirection='row' alignItems='center'>
			<Typography width={40} fontSize='lg' fontWeight='bold'>{available ? rankPlace.no : '-'}</Typography>
			<Typography flex={1} fontSize='lg' fontWeight='bold'>{rankPlace.school.shortName}</Typography>
			<Box display='flex' flexDirection='column' alignItems='end'>
				<Typography fontSize='xs' textColor='#999'>{rankPlace.school.area}</Typography>
				<Box display='flex' flexDirection='row' gap={0.5}>
					{rankPlace.school.gender !== 'Mixed' && <Chip variant='soft' size='sm' sx={{ color: '#999' }}>{rankPlace.school.gender}</Chip>}
					{rankPlace.school.gep && <Chip variant='soft' size='sm' sx={{ color: '#999' }}>GEP</Chip>}
					{rankPlace.school.sap && <Chip variant='soft' size='sm' sx={{ color: '#999' }}>SAP</Chip>}
				</Box>
			</Box>
		</Box>
		<Box display='flex' flexDirection='row' ml={5} justifyContent='space-between'>
			<canvas ref={canvasRef} width={160} height={48}></canvas>
			<Box display='flex' flexDirection='column' textAlign='right'>
				{eligible ? (available
					? <Typography fontSize='lg' fontWeight='bold' mt={0.5}>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
					: <Typography fontSize='lg' fontWeight='bold' mt={0.5}>-</Typography>)
				: <Typography fontSize='xs' textColor='#999' mt={0.5}>{noVac}</Typography>}
				{eligible && <Typography fontSize='xs' textColor='#999'><FA icon={faChair} color='#bbb' /> {rankPlace.sum.vacancy} <FA icon={faChild} color='#bbb' /> {rankPlace.sum.applied}</Typography>}
			</Box>
		</Box>
	</Box> :
	<Grid container spacing={2} flexGrow={1} alignItems='center' p={0} m={0} sx={{ whiteSpace: 'nowrap', borderBottom: Styles.divider }}>
		<Grid xs={0.7}><Typography fontSize='lg' fontWeight='bold'>{available ? rankPlace.no : '-'}</Typography></Grid>
		<Grid flex={1}><Typography fontSize='lg' fontWeight='bold'>{rankPlace.school.shortName}</Typography></Grid>
		<Grid xs={1} textAlign='center'>{rankPlace.school.gep && <Typography fontSize='xs' textColor='#999'>GEP</Typography>}</Grid>
		<Grid xs={1} textAlign='center'>{rankPlace.school.sap && <Typography fontSize='xs' textColor='#999'>SAP</Typography>}</Grid>
		<Grid xs={1} textAlign='center'>{rankPlace.school.gender !== 'Mixed' && <Typography fontSize='xs' textColor='#999'>{rankPlace.school.gender}</Typography>}</Grid>
		<Grid xs={1.35} ml={1} textAlign='left'><Typography fontSize='xs' textColor='#999'>{rankPlace.school.area}</Typography></Grid>
		<Grid xs='auto' mx={1}><canvas ref={canvasRef} width={160} height={48}></canvas></Grid>
		<Grid xs={0.9}>
			{eligible ? (available
				? <Typography fontSize='lg' fontWeight='bold'>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
				: <Typography fontSize='lg' fontWeight='bold'>-</Typography>)
			: <Typography fontSize='xs' textColor='#999'>{noVac}</Typography>}
			{eligible && <Typography fontSize='xs' textColor='#999'>{`${rankPlace.sum.vacancy} / ${rankPlace.sum.applied}`}</Typography>}
		</Grid>
	</Grid>;
}

export default RankPage;

