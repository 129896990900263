import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { P2b2cLogo } from '../Resources';
import { Box, Stack, Typography, Link, Divider, Chip, Badge } from '@mui/joy';


const Footer: React.FC = () => {
    const nav = useNavigate();
    return <Box component='nav' display='flex' alignItems='center' justifyContent='center' mt={4} sx={{ bgcolor: '#fbfcfe' }}>
        <Stack flex={1} m={2} spacing={2} alignItems='start' sx={{ maxWidth: 1180 }}>
            <P2b2cLogo size='20' color='#666' />
            <Divider />
            <Box display='flex' flexWrap='wrap' gap={3} sx={{ width: '100%' }}>
                <Typography fontSize='xs' textColor='#999'>{`Copyright © 2024 Omnimind Pte. Ltd. All rights reserved.`}</Typography>
                <Box display='flex' flex={1} justifyContent='flex-end'><img alt='' src='/images/omnimind_grey_icon.svg' width={100} /></Box>
            </Box>
        </Stack>
    </Box>;
}

export default Footer;